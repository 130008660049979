import { Component, Prop, Watch } from 'vue-property-decorator';

import { GalleryItem } from '~/components/molecules';
import { GalleryItemInterface } from '~/components/molecules/galleryItem/GalleryItem';
import { CztWidgets } from '~/utils/views/widgets';
import { VueComponent } from '~/utils/vue-component';

import style from './GalleryGrid.scss';
import { GalleryInterface } from './types';
import Dialog from '../dialog/Dialog';
import Headline, { Align } from '~/components/atoms/headline/Headline';
import { Ratios } from '~/utils/theme/ratios';
import GalleryGridItem from './GalleryGridItem';
import { LocaleMessage } from 'vue-i18n';

const rootClass: string = 'czt-gallery-grid';

@Component({
  style,
})
export default class GalleryGrid extends VueComponent<GalleryInterface>
  implements GalleryInterface {
  @Prop()
  public anchorId?: string;

  @Prop({ required: true, type: Array })
  public items!: GalleryItemInterface[];

  @Prop({ default: false, type: Boolean })
  public eager!: boolean;

  @Prop({ type: Boolean, default: false })
  public isFirst!: boolean;

  @Prop()
  public title?: string | LocaleMessage;

  public className = CztWidgets.GALLERY;

  protected lightboxModel: number | null = null;

  protected isSm = this.$vuetify.breakpoint.smAndDown;

  public render() {
    if (this.items.length < 1) {
      return;
    }
    const classes = [rootClass];

    return (
      <v-container id={this.anchorId}>
        <v-row no-gutters>
          {this.title && this.getHeadline()}
          <v-col cols={12}>
            <div class={classes.join(' ')}>
              <GalleryItem
                isFirst={true}
                hideTitle={true}
                disableContent={true}
                onClick={() => this.openLightbox(0)}
                icon={this.items[0].icon}
                image={this.items[0].image}
                imageFilter={this.items[0].imageFilter}
                imageSource={this.items[0].imageSource}
                ratio={this.items[0].ratio || Ratios['16x9']}
                subtitle={this.items[0].subtitle}
                title={this.items[0].title}
                video={this.items[0].video}
                showVideoControls={this.items[0].showVideoControls}
              />
              {this.items.length > 1 && (
                <div class={`${rootClass}__thumbs`}>
                  {this.items.slice(1, 4).map((item, index) => (
                    <div
                      class={`${rootClass}__thumbs__wrapper ${
                        this.items.length > 4 && index === 2
                          ? `${rootClass}__toggle`
                          : ''
                      }`}
                    >
                      <GalleryItem
                        key={index}
                        hideTitle={true}
                        disableContent={true}
                        onClick={() => this.openLightbox(index + 1)}
                        icon={item.icon}
                        image={item.image}
                        imageFilter={item.imageFilter}
                        ratio={this.isSm ? Ratios['1x1'] : Ratios['16x9']}
                        subtitle={item.subtitle}
                        title={item.title}
                        video={item.video}
                      />
                      {this.items.length > 4 && index === 2 && (
                        <div
                          class={`${rootClass}__additional`}
                          onClick={() => this.openLightbox(index + 1)}
                        >
                          <Headline light level={4}>
                            +{this.items.length - 4}
                          </Headline>
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              )}
            </div>
          </v-col>
          <Dialog
            onKeydown={(e: KeyboardEvent) => {
              if (e.key === 'Escape') {
                this.closeLightbox();
              }
              if (this.lightboxModel !== null) {
                if (e.key === 'ArrowLeft') {
                  this.lightboxModel =
                    (this.lightboxModel - 1 + this.items.length) %
                    this.items.length;
                }
                if (e.key === 'ArrowRight') {
                  this.lightboxModel =
                    (this.lightboxModel + 1) % this.items.length;
                }
              }
            }}
            active={this.lightboxModel !== null}
            onCloseDialog={this.closeLightbox}
            fullScreen
            eager
          >
            <v-sheet
              height='100%'
              max-height='100vh'
              width='100%'
              max-width='100%'
            >
              <v-carousel
                value={this.lightboxModel}
                class={`${rootClass}__lightbox`}
                show-arrows={this.items.length > 1}
                show-arrows-on-hover
                height='100%'
                hide-delimiter-background
                hide-delimiters={this.items.length < 2}
              >
                {this.items.map((item: GalleryItemInterface, index: number) => {
                  return (
                    <GalleryGridItem
                      key={item.title + index}
                      image={item.image}
                      title={item.title}
                      eager={this.eager}
                      imageSource={item.imageSource}
                    />
                  );
                })}
              </v-carousel>
            </v-sheet>
          </Dialog>
        </v-row>
      </v-container>
    );
  }

  protected getHeadline() {
    return (
      <v-col class='py-0'>
        <Headline underscore align={Align.LEFT} level={2}>
          {this.title}
        </Headline>
      </v-col>
    );
  }

  protected openLightbox(i: number) {
    this.lightboxModel = i;
  }

  protected closeLightbox() {
    this.lightboxModel = null;
  }

  @Watch('$vuetify.breakpoint.smAndDown')
  protected updateIsSm() {
    this.isSm = this.$vuetify.breakpoint.smAndDown;
  }
}
